<template>
    <div class="col-12">
        <!-- 1st Overlay -->

        <recess-card variant="variant1" class="mb-4 p-4">
            <div class="d-flex w-60">
                <recess-input
                    :value="postEdudexImport.directoryUrl"
                    :disabled="isInProgress"
                    :placeholder-text="PROVIDER.Portfolio.EdudexFeed.SearchPlaceholder"
                    class="w-100 qa-edudex-feed-url"
                    @input="(newValue) => setEdudexImportDetails(newValue)"
                />
            </div>

            <div class="mt-3">
                <div class="d-flex align-items-center">
                    <recess-button
                        variant="secondary"
                        :title="BUTTON_TEXT.testFeed"
                        :disabled="isFeedButtonDisabled"
                        class="mr-4 qa-test-feed-button"
                        @click.native.prevent="testFeed"
                    />
                    <recess-button
                        variant="secondary"
                        :title="BUTTON_TEXT.startFeed"
                        :disabled="isFeedButtonDisabled"
                        class="mr-4 qa-start-feed-button"
                        @click.native.prevent="startFeed"
                    />
                    <p class="m-0 qa-explanation-text">
                        {{ PROVIDER.Portfolio.EdudexFeed.StartFeedExplanationText }}
                    </p>
                </div>

                <div v-if="showTestFeedDetails || showStartFeedDetails" class="mt-3">
                    <p v-if="(!isValidationDone || !isImportDone) && !isInvalid">
                        {{ PROVIDER.Portfolio.EdudexFeed.WarningMessage }}
                    </p>

                    <p>
                        <span class="u-text-semi-bold">{{
                            PROVIDER.Portfolio.EdudexFeed.Label.ValidationProgress
                        }}</span>
                        <span class="qa-edudex-validation-progress">{{
                            `${edudexImport.validationProgress}%`
                        }}</span>
                    </p>

                    <p v-if="showStartFeedDetails">
                        <span class="u-text-semi-bold">{{
                            PROVIDER.Portfolio.EdudexFeed.Label.ImportProgress
                        }}</span>
                        <span class="qa-edudex-import-progress">{{
                            `${edudexImport.importProgress}%`
                        }}</span>
                    </p>

                    <p>
                        <span class="qa-result-label u-text-semi-bold">{{ resultsLabel }}</span>
                        <span class="qa-total-import"
                            >{{ PROVIDER.Portfolio.EdudexFeed.Label.Total }}
                            {{ edudexImport.total }}</span
                        >

                        <span class="u-color-success ml-2 qa-valid-import">{{ setResult }}</span>
                        <span class="u-color-error ml-2 qa-invalid-import"
                            >{{ edudexImport.invalid }}
                            {{
                                PROVIDER.Portfolio.EdudexFeed.ImportProgramStatus.Invalid
                                    .Description
                            }}</span
                        >
                        <span v-if="showStartFeedDetails" class="ml-2 qa-unmodified-import"
                            >{{ edudexImport.unmodified || 0 }}
                            {{
                                PROVIDER.Portfolio.EdudexFeed.ImportProgramStatus.Unmodified
                                    .Description
                            }}</span
                        >
                    </p>

                    <p>
                        <span class="u-text-semi-bold">{{
                            PROVIDER.Portfolio.EdudexFeed.Label.Status
                        }}</span>
                        <span
                            v-if="isValidTestFeedInProgress || isValidStartFeedInProgress"
                            class="qa-import-in-progress"
                            >{{ edudexImport.statusDisplayValue }}</span
                        >
                        <span
                            v-if="isValidTestFeedComplete || isValidStartFeedComplete"
                            class="qa-import-complete"
                            >{{ edudexImport.statusDisplayValue }}</span
                        >
                        <span v-if="isInvalid" class="qa-import-invalid">{{
                            edudexImport.statusDisplayValue
                        }}</span>
                    </p>
                    <recess-button
                        variant="secondary"
                        :title="BUTTON_TEXT.viewDetails"
                        :disabled="isInProgress"
                        class="qa-test-feed-details-button"
                        @click.native.prevent="goToEdudexImportDetails"
                    />
                </div>
            </div>
        </recess-card>

        <!-- show opgeslagen feed after edudex import has started -->

        <!-- 2nd Overlay -->
        <div v-if="hasSavedFeed">
            <h4 class="ml-4 mb-4 qa-saved-feed-label">
                {{ PROVIDER.Portfolio.EdudexFeed.Label.SavedFeed }}
            </h4>

            <recess-card variant="variant1" class="p-4">
                <div class="d-flex align-items-center justify-content-between">
                    <h4 class="m-0 w-70 u-word-break qa-saved-import-url">
                        {{ provider.importUrl }}
                    </h4>

                    <div class="d-flex align-items-center">
                        <!-- import from stored feed button -->
                        <recess-button
                            variant="secondary"
                            :title="BUTTON_TEXT.importNow"
                            :disabled="isInProgress || isAdministrator"
                            class="mr-3 qa-start-saved-feed-button"
                            @click.native.prevent="importCurrentFeedUrl"
                        />
                        <!-- delete current feed -->
                        <span
                            class="c-icon c-icon__bin qa-remove-saved-feed-button"
                            @click="removeEdudexFeed()"
                        >
                        </span>
                    </div>
                </div>
            </recess-card>
        </div>

        <!-- show no current feed placeholder text -->
        <recess-card v-else variant="variant1" class="p-4">
            <h4 class="mb-0 qa-no-saved-feed-text">{{ PROVIDER.Portfolio.EdudexFeed.NoSavedFeedText }}</h4>
        </recess-card>
    </div>
</template>

<script>
import { PROVIDER } from '@/constants/EdumsProviderConstants.json'

import { mapState, mapActions, mapMutations } from 'vuex'
import { postItem, getItemById } from '@/../../shared/api/SharedClient'
import { API_CALL_URL_PATHS, BUTTON_TEXT } from '@/../../shared/constants/constantsGeneral.json'
import UserService from '../../../../../../shared/services/UserService'
import { defaultErrorMessage, importSuccessAction, invalidEdudexDirectoryError, invalidXmlFileError, noProgramResourceError } from '../../../../../../shared/constants/validationMessageHelper'

export default {
    name: 'EdudexFeed',
    data() {
        return {
            PROVIDER,
            userService: new UserService(),
            isAdministrator: false,
            BUTTON_TEXT,
            timeout: null,
            importId: null,
            showTestFeedDetails: false,
            showStartFeedDetails: false,
            postEdudexImport: {
                providerId: '',
                emailAddress: '',
                directoryUrl: '',
                isAutoStart: false
            },
            edudexImport: {
                importProgress: 0,
                validationProgress: 0,
                valid: 0,
                invalid: 0,
                succeeded: 0,
                total: 0,
                status: ''
            },
            userName: null
        }
    },
    async created() {
        this.isAdministrator = await this.userService.isAdministrator()
        this.userName = await this.userService.getUserName()
    },
    computed: {
        ...mapState('providerModule', ['provider', 'selectedProvider']),
        resultsLabel() {
            return this.showStartFeedDetails
                ? this.PROVIDER.Portfolio.EdudexFeed.Label.ImportResults
                : this.PROVIDER.Portfolio.EdudexFeed.Label.TestResults
        },
        setResult() {
            return this.showStartFeedDetails
                ? `${this.edudexImport.succeeded} ${this.PROVIDER.Portfolio.EdudexFeed.ImportProgramStatus.Succeeded.Description}`
                : `${this.edudexImport.valid} ${this.PROVIDER.Portfolio.EdudexFeed.ImportProgramStatus.Valid.Description}`
        },
        isInProgress() {
            return (
                this.edudexImport &&
                (this.edudexImport.status ===
                    this.PROVIDER.Portfolio.EdudexFeed.ImportStatus.ValidatingPrograms.Value ||
                    this.edudexImport.status ===
                        this.PROVIDER.Portfolio.EdudexFeed.ImportStatus.InProgress.Value ||
                    this.edudexImport.status ===
                        this.PROVIDER.Portfolio.EdudexFeed.ImportStatus.RetrievingProgramUrls.Value)
            )
        },
        isValidTestFeedInProgress() {
            return this.showTestFeedDetails && !this.isValidationDone && !this.isInvalid
        },
        isValidTestFeedComplete() {
            return this.showTestFeedDetails && this.isValidationDone && !this.isInvalid
        },
        isValidStartFeedInProgress() {
            return this.showStartFeedDetails && !this.isImportDone && !this.isInvalid
        },
        isValidStartFeedComplete() {
            return this.showStartFeedDetails && this.isImportDone && !this.isInvalid
        },
        hasSavedFeed() {
            return this.provider && this.provider.importUrl && this.provider.importUrl !== ''
        },
        isFeedButtonDisabled() {
            return (
                this.isInProgress ||
                this.postEdudexImport.directoryUrl === '' ||
                this.isAdministrator
            )
        },
        setObjectToBePatched() {
            return [
                {
                    value: this.provider.importUrl,
                    path: 'importUrl',
                    op: 'replace'
                }
            ]
        },
        isInvalid() {
            if (
                this.edudexImport &&
                this.edudexImport.status ===
                    this.PROVIDER.Portfolio.EdudexFeed.ImportStatus.Invalid.Value
            ) {
                this.$bus.emit('edudexFeedProgress', true)
                return true
            }
            this.$bus.emit('edudexFeedProgress', false)
            return false
        },
        isImportDone() {
            if (
                this.edudexImport &&
                this.edudexImport.importProgress === '100' &&
                this.edudexImport.status ===
                    this.PROVIDER.Portfolio.EdudexFeed.ImportStatus.Completed.Value
            ) {
                this.$bus.emit('edudexFeedProgress', true)
                return true
            }
            this.$bus.emit('edudexFeedProgress', false)
            return false
        },
        isValidationDone() {
            if (
                this.edudexImport &&
                this.edudexImport.validationProgress === '100' &&
                this.edudexImport.status ===
                    this.PROVIDER.Portfolio.EdudexFeed.ImportStatus.PendingStart.Value
            ) {
                this.$bus.emit('edudexFeedProgress', true)
                return true
            }
            this.$bus.emit('edudexFeedProgress', false)
            return false
        }
    },
    watch: {
        edudexImport: {
            handler() {
                this.getDetailsFromImport()
            },
            deep: true
        }
    }, 
    beforeDestroy() {
        clearTimeout(this.timeout)
    },
    methods: {
        ...mapActions('providerModule', ['patchProvider']),
        ...mapMutations('providerModule', ['setProviderImportUrl', 'resetProviderImportUrl']),
        // set url, email, providerId required to post edudex import when user types in url in input field
        setEdudexImportDetails(newValue) {
            this.postEdudexImport.directoryUrl = newValue
            this.postEdudexImport.providerId = this.provider.id
            this.postEdudexImport.emailAddress = this.userName
        },
        testFeed() {
            this.showStartFeedDetails = false
            this.showTestFeedDetails = true
            this.postEdudexImport.isAutoStart = false
            this.postEdudexImportDetails()
        },
        startFeed() {
            this.showTestFeedDetails = false
            this.showStartFeedDetails = true
            this.postEdudexImport.isAutoStart = true
            this.postEdudexImportDetails()
        },
        updateProviderImportUrl() {
            this.setProviderImportUrl(this.postEdudexImport.directoryUrl)
            if (!this.provider.importUrl) return

            this.patchProvider({
                providerId: this.provider.id,
                objectToBePatched: this.setObjectToBePatched
            })
        },
        async postEdudexImportDetails() {
            try {
                const response = await postItem(
                    process.env.VUE_APP_PROVIDER_API_URL,
                    API_CALL_URL_PATHS.edudexImports,
                    this.postEdudexImport,
                    true
                )
                if (!response) return
                this.importId = response.id
                this.resetEdudexImportDetails()
            } catch (error) {
                this.getNotificationError(error)
                console.error('Something went wrong while posting edudex import details', error)
            }
        },
        getNotificationError(error, message = '') {
            const toastNotification = {
                type: 'error',
                message: message
            }

            const errorData = error && error.response && error.response.data

            if (errorData) {
                if (
                    errorData.error &&
                    errorData.error.modelState &&
                    errorData.error.modelState.DirectoryUrl &&
                    errorData.error.modelState.DirectoryUrl[0] &&
                    errorData.error.modelState.DirectoryUrl[0] ===
                        'DirectoryUrl is not a valid EdudexDirectory.'
                ) {
                    toastNotification.message = invalidEdudexDirectoryError
                } else if (
                    errorData.error &&
                    errorData.error.modelState &&
                    errorData.error.modelState.DirectoryUrl &&
                    errorData.error.modelState.DirectoryUrl[0] &&
                    errorData.error.modelState.DirectoryUrl[0] ===
                        'DirectoryUrl does not contain a ProgramResource.'
                ) {
                    toastNotification.message = noProgramResourceError
                } else if (
                    errorData.error &&
                    errorData.error.modelState &&
                    errorData.error.modelState.DirectoryUrl &&
                    errorData.error.modelState.DirectoryUrl[0] &&
                    errorData.error.modelState.DirectoryUrl[0] ===
                        'DirectoryUrl is not a valid XML document.'
                ) {
                    toastNotification.message = invalidXmlFileError
                } else {
                    toastNotification.message = defaultErrorMessage
                }
            }

            this.$store.dispatch('toastNotificationModule/add', toastNotification, {
                root: true
            })
        },
        getDetailsFromImport() {
            this.timeout = setTimeout(() => {
                if (this.isValidTestFeedInProgress || this.isValidStartFeedInProgress) {
                    this.getEdudexImportDetails()
                }

                // patch provider import url right after edudex import is successfully completed
                if (this.isValidStartFeedComplete) {
                    this.updateProviderImportUrl()
                }
            }, 5000)
        },
        async getEdudexImportDetails() {
            try {
                if (!this.importId) return
                const response = await getItemById(
                    process.env.VUE_APP_PROVIDER_API_URL,
                    API_CALL_URL_PATHS.edudexImports,
                    this.importId,
                    null,
                    true
                )
                if (!response) return
                this.edudexImport = response

                if (
                    this.edudexImport.status ===
                    this.PROVIDER.Portfolio.EdudexFeed.ImportStatus.Completed.Value
                ) {
                    const toastNotification = {
                        type: 'success',
                        message: importSuccessAction('afgerond')
                    }

                    this.$store.dispatch('toastNotificationModule/add', toastNotification, {
                        root: true
                    })
                }
            } catch (error) {
                console.error('Something went wrong while retrieving edudex import details', error)
            }
        },
        resetEdudexImportDetails() {
            this.edudexImport.valid = 0
            this.edudexImport.invalid = 0
            this.edudexImport.total = 0
            this.edudexImport.validationProgress = 0
            this.edudexImport.importProgress = 0
            this.edudexImport.succeeded = 0
            this.edudexImport.status =
                this.PROVIDER.Portfolio.EdudexFeed.ImportStatus.RetrievingProgramUrls.Value
        },
        importCurrentFeedUrl() {
            this.setEdudexImportDetails(this.provider.importUrl)
            this.startFeed()
        },
        removeEdudexFeed() {
            this.resetProviderImportUrl()
            this.patchProvider({
                providerId: this.provider.id,
                objectToBePatched: this.setObjectToBePatched
            })
        },
        goToEdudexImportDetails() {
            if (!this.importId) return
            this.$router.push({
                name: 'edudex-import-details',
                params: { id: this.importId }
            })
        }
    }
}
</script>

